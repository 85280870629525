<template>
    <div class="module-preview-wrapper">
        <div class="module-preview--header">featured amenities</div>
        <div class="module-preview--contents">
            <div class="text-wrapper">
                <span class="font-inter font-600 text-lg">{{ getValue('headline') }}</span>            
                <p class="mt-4 text-sm pr-12">{{ getValue('description') }}</p>
            </div>
            <div class="list-wrapper">
                <ul>
                    <li class="text-sm" v-for="(amenity,index) in featuredAmenities" :key="index">                        
                        {{ amenity.amenity }}
                    </li>
                </ul>
            </div>
        </div>
    </div>     
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";

export default {
    mixins: [ Widget ],
    data: () => {
        return {
            featuredAmenities: []
        }
    },
    computed: {
        ...mapGetters({
            getCustomerAmenityByName: 'amenities/getCustomerAmenityByName',
        }),
    },
    created() {
        this.featuredAmenities = JSON.parse(this.getValue('amenities'));
    }
}
</script>

<style scoped>   
    .module-preview--contents {
        grid-template-columns: 1fr 1fr;
    } 
    .list-wrapper {
        align-self: center;
    }
    .list-wrapper ul {
        column-count: 2;
        list-style: disc;
    }   
    .list-wrapper li {
        line-height: 1.75;
    }
</style>
